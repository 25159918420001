
// Expand Responsive Values
const getTemplateFromUrl = (url) => {

    url = url.trim()
    url = url.replace(/^\//g, '');

    let result

    const rules = [
        {
            startsWith: 'blog/',
            result: '/blog/[[...slug]]'
        },
        {
            startsWith: 'category/',
            result: '/category/[...slug]'
        },
        {
            startsWith: 'products',
            result: '/products'
        },
        {
            startsWith: 'products/',
            result: '/products/[slug]/[[...tab]]'
        }
        
    ]

    rules.forEach(rule => {

        if(rule.startsWith && url.startsWith(rule.startsWith)){
            result =  rule.result
        }

    })

    return result || "/[...slug]"

}

export default getTemplateFromUrl